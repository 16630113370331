<!-- 上传转账凭证 -->
<template>
  <div class="transferEvidence center-wrap">
    <div class="page">
      <h1 v-text="$t('transfer.upload')">上传转帐凭证</h1>
      <div class="text-box" v-for="item,index in acount">
        <p class="text-title" v-text="$t('transfer.make_money') + (index+1)">收款信息</p>
        <div>
          <div>
            <h2 v-text="$t('transfer.name')">收款单位名称：</h2>
            <p v-text="item.company_name">重庆云端数据有限公司</p>
          </div>
          <div>
            <h2 v-text="$t('transfer.bank')">收款单位及开户银行：</h2>
            <p v-text="item.bank_name">中国工商银行观音桥支行</p>
          </div>
          <div>
            <h2 v-text="$t('transfer.bank_num')">收款单位银行账户：</h2>
            <p v-text="item.bank_account">中国工商银行观音桥支行</p>
          </div>
        </div>
      </div>
      <div class="write-box">
        <p class="text-title" v-text="$t('transfer.information')">转行信息</p>
        <div>
          <p v-text="$t('transfer.explain')">备注说明</p>
          <textarea :placeholder="$t('transfer.input')" v-model="remark" maxlength="100"></textarea>
        </div>
      </div>
      <p class="photo-title" v-text="$t('transfer.go_upload')">上传凭证</p>
      <div class="photo-box">
        <div class="book" v-for="item,index in fileList">
          <img src="../assets/icon/pdf.png" v-if="item.indexOf('.pdf') >= 0" />
          <img :src="item" v-else />
          <p class="click" v-text="$t('transfer.delete')" @click.stop="fileList.splice(index,1)">删除</p>
        </div>
        <!-- 添加图片 -->
        <div class="add click" v-if="fileList.length < 3">
          <img src="../assets/icon/add.png" />
          <input
            type="file"
            accept="image/*, application/pdf"
            @change="handleFileChange"
            ref="uploadFile"
          />
        </div>
      </div>
      <p class="remark">{{$t('transfer.remark')}}</p>
      <div class="submit click" v-text="$t('transfer.botton')" @click.stop="fnSubmit">提交</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TransferEvidence',
  data() {
    return {
      remark: null,
      userinfo: null,
      acount: [],
      fileList: [],
    }
  },
  created: function () {
    var userinfo = sessionStorage.getItem('userinfo')
    this.userinfo = JSON.parse(userinfo)
    this.accountInfo()
  },
  methods: {
    fnSubmit: function () {
      var self = this
      if (!self.remark)
        return self.common.showMessage(self.$t('transfer.input_info'), 'error') //请输入备注
      if (self.fileList.length == 0)
        return self.common.showMessage(self.$t('fb.photo'), 'error') //请上传图片
      var order_code = self.$route.query.order_code
      self.common
        .httpPost('/api/order/payOrder', {
          order_code: order_code,
          pay_type: 4,
          remark: self.remark,
          images: self.fileList.toString(),
        })
        .then((ret) => {
          self.common.showMessage(ret.msg, ret.code == 1 ? 'success' : 'error')
          if (ret.code == 1) {
            self.$router.replace('/order/list')
          }
        })
    },
    accountInfo: function () {
      var self = this
      self.common
        .httpPost('/api/ajax/accountInfo', { local: this.userinfo.local })
        .then((ret) => {
          if (ret.code == 1) {
            self.acount = ret.data
          } else self.common.showMessage(ret.msg, 'error')
        })
    },
    handleFileChange() {
      var self = this
      var file = this.$refs.uploadFile.files[0]
      if (!file) return
      var formdata = new FormData()
      formdata.append('file', file, file.name)
      let config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
      this.common.showLoading(self.$t('fb.photo_logoing')) //图片上传中...
      axios
        .post('/api/ajax/uploadImage', formdata, config)
        .then((response) => {
          self.common.hideLoading()
          if (response.data.code == 1) {
            var url = response.data.url
            self.fileList.push(url)
          } else self.common.showMessage(self.$t('fb.photo_bad'), 'error') //图片上传失败
        })
        .catch((error) => {
          self.common.hideLoading()
          // console.log(JSON.stringify(error));
          var originalRequest = error.config
          if (
            error.code == 'ECONNABORTED' &&
            error.message.indexOf('timeout') != -1
          ) {
            self.common.showMessage(self.$t('fb.photo_timeout'), 'error') //图片上传失败
          } else {
            self.common.showMessage(self.$t('fb.photo_bad'), 'error') //图片上传失败
          }
        })
      this.$refs.uploadFile.value = null
    },
  },
  components: {},
}
</script>
<style scoped>
.page {
  margin-left: 283px;
  margin-right: 283px;
}

h1 {
  font-size: 20px;
  color: #999999;
  line-height: 20px;
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
}

.text-box {
  width: 800px;
  border: 1px solid rgba(221, 221, 221, 0.7);
  margin-bottom: 30px;
}

.text-title {
  padding-left: 30px;
  line-height: 50px;
  font-weight: 500;
  color: rgba(171, 171, 171, 1);
  background: #f7f6f6;
}

.text-box > div {
  margin: 26px 30px 29px 30px;
}

.text-box div div {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.text-box div div:last-child {
  margin-bottom: 0px;
}

h2 {
  width: 130px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 12px;
}

.text-box div div p {
  font-size: 12px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 12px;
}

.write-box {
  margin-bottom: 50px;
  border-radius: 4px;
  border: 1px solid rgba(221, 221, 221, 0.7);
  overflow: hidden;
}

.write-box div {
  padding: 16px 30px;
}

.write-box div p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 16px;
  margin-bottom: 10px;
}

.write-box div textarea {
  height: 30px;
  width: 100%;
}

.photo-title {
  font-size: 14px;
  color: #333333;
  line-height: 16px;
  margin-bottom: 16px;
}

.photo-box {
  width: 835px;
  display: flex;
  flex-wrap: wrap;
}

.book {
  height: 109px;
  width: 109px;
  position: relative;
  margin-right: 30px;
  margin-bottom: 20px;
}

.book p {
  height: 24px;
  width: 109px;
  font-size: 14px;
  color: #ffffff;
  line-height: 24px;
  background: rgba(153, 153, 153, 0.8);
  text-align: center;
  position: absolute;
  bottom: 0;
}

.book img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.add img {
  height: 28px;
  width: 28px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.add {
  height: 110px;
  width: 110px;
  border: 1px solid rgba(204, 204, 204, 0.7);
  border-radius: 2px;
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
}

.add input {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  height: 110px !important;
  width: 110px !important;
}

.submit {
  height: 35px;
  width: 160px;
  margin: 60px auto;
  background: #297fab;
  font-size: 16px;
  text-align: center;
  line-height: 35px;
  color: #ffffff;
  border-radius: 4px;
}

.remark {
  width: 350px;
  color: #666;
  font-size: 12px;
}
</style>